import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const SemkaKlipov = () => {
  return (
    <>
      <Helmet>
        <title>Съемка клипов в Москве цена от студии Elandic</title>
        <meta name="description" content="Съемка музыкальных клипов в Москве цена услуг на сайте студии Elandic. Заказать профессиональную запись клипов для песен стоимость от видеопродакшена." />
        <meta name="keywords" content="съемка клипа, москва, музыкальных, студия, песни, запись, стоимость, цена" />
      </Helmet>
      <MainPage>
        <SeoText title='Съемка клипов'>
          <p>В эпоху визуальной культуры музыкальный клип – не просто аккомпанемент к песне, а полноценное произведение искусства, способное раскрыть глубину и атмосферу музыкального трека. Студия Elandic в Москве предлагает услуги по съемке музыкальных клипов в Москве, обеспечивая их уникальность, профессиональное выполнение и доступность для широкого круга клиентов благодаря гибкой системе ценообразования.</p>

          <p>Elandic – это студия, которая сочетает в себе креативный подход и техническое мастерство, необходимые для создания музыкальных клипов, способных воплотить в жизнь любые задумки артистов. Мы предлагаем полный цикл работ по съемке – от предварительной консультации, разработки концепции и сценария до постпродакшена и финальной обработки видео.</p>

          <p>Работая с разнообразным спектром жанров и тематик, наша студия предоставляет услуги как начинающим музыкантам, так и узнаваемым артистам. Стоимость съемки клипа варьируется в зависимости от множества факторов, включая сложность проекта, необходимость привлечения дополнительных ресурсов и особенности локаций. Однако наша главная задача – сделать этот процесс максимально комфортным и доступным для вас, что позволяет нам предложить оптимальное соотношение цены и качества на рынке Москвы.</p>

          <p>
            Студия Elandic гордится возможностью участвовать в создании музыкальных и визуальных произведений искусства, которые вдохновляют и оставляют неизгладимое впечатление. Мы всегда открыты для новых проектов и идей, стремясь превысить ожидания наших клиентов. Съемка клипа в Москве от студии Elandic – это ваш ключ к успеху в мире музыки и видеоиндустрии. Выбирая нас, вы выбираете качество, профессионализм и индивидуальный подход к каждому проекту.

          </p>
        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(SemkaKlipov);
